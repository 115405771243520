import store from '@/store';
import Http from '../Http';

// Services
import TCloudService from '@/utils/TCloudService';
import ErrorService from '@/utils/ErrorService';

// API
import {
    attachFilesToDesignTask,
    removeFilesFromDesignTask,
    returnCompletedDesign,
    toProductionDesign,
} from '@/utils/Api';

// Utils
import { hasPermission } from '@/utils/Permissions';
import { get, last, pick } from 'lodash';

// Enums
import DesignStatusEnum from '@/ship/Enums/DesignStatusEnum';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

// Interfaces
import Design, { DesignRemark, DesignTask, DesignUpdateModel } from '../Models/Design';
import User from '../Models/User';
import ITransformedValue, { IIncludedValue } from '../Values/ITransformedValue';
import IFile from '@/ship/Models/IFile';

export default class DesigningService {
    public static toUpdateModel(design: Design): DesignUpdateModel {
        return {
            ...pick(design, ['projectId', 'deadline', 'stage', 'name']),
            authors: this.getAuthors(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            inspectors: this.getInspectors(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            designers: this.getDesigners(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            executors: this.getExecutors(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            customerManagers: this.getCustomerManagers(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            generalDesignersManagers: this.getGeneralDesignersManagers(design)
                .filter((el) => !el.taskInfo?.isSubstituting)
                .map(({ id }) => id),
            generalDesigners: design.generalDesigners?.data?.map(({ id }) => id) ?? [],
        };
    }

    /**
     * Является ли пользователь Представителем заказчика
     */
    public static isRepresentative(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;

        return design.authors?.data.some(({ id }) => id === userId) ?? false;
    }

    /**
     * Является ли пользователь Куратором заказчика или Региональный руководитель со стороны заказчика
     */
    public static isInspector(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;
        const isInspector = design.inspectors?.data.some(({ id }) => id === userId) ?? false;
        const isCustomerManager = design.customerManagers?.data.some(({ id }) => id === userId) ?? false;
        return isInspector || isCustomerManager;
    }

    /**
     * Является ли пользователь Автором замечаний
     */
    public static isAuthor(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;

        return design.authors?.data.some(({ id }) => id === userId) ?? false;
    }

    /**
     * Является ли пользователь Куратором проектной организации
     */
    public static isDesigner(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;
        const isDesigner = design.designers?.data?.some(({ id }) => id === userId) ?? false;
        return isDesigner;
    }

    /**
     * Является ли пользователь Главный специалист со стороны Проектной организации
     */
    public static isGeneralDesignerManager(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;
        const isGeneralDesignerManager =
            design.generalDesignersManagers?.data?.some(({ id }) => id === userId) ?? false;
        return isGeneralDesignerManager;
    }

    /**
     * Является ли пользователь Представителем от проектной организации
     */
    public static isExecutor(design: Design, user?: User): boolean {
        const userId = user ? user.id : store.state.user?.id;

        return design.executors?.data.some(({ id }) => id === userId) ?? false;
    }

    /**
     * Присутствует ли пользователь в данных чертежа
     */
    public static hasAccessToDesign(design: Design, user?: User): boolean {
        const hasAccessToDesign =
            DesigningService.isRepresentative(design, user) ||
            DesigningService.isInspector(design, user) ||
            DesigningService.isDesigner(design, user) ||
            DesigningService.isGeneralDesignerManager(design, user) ||
            DesigningService.isExecutor(design, user);
        return hasAccessToDesign;
    }

    /**
     * Является ли пользователь ответственным/создателем задачи
     */
    public static isTaskInspector(design: Design, user?: User): boolean {
        return (
            DesigningService.isInspector(design, user) ||
            DesigningService.isDesigner(design, user) ||
            DesigningService.isGeneralDesignerManager(design, user) ||
            DesigningService.isRepresentative(design, user)
        );
    }

    /**
     * Может ли пользователь перевести чертеж в статус "В работе"
     */
    public static canReturnToWorkDesign(design: Design) {
        if (!design.status) return false;

        return (
            [
                DesignStatusEnum.adopted,
                DesignStatusEnum.inProduction,
                DesignStatusEnum.cancel,
                DesignStatusEnum.requestReturn,
            ].includes(design.status) && DesigningService.isInspector(design)
        );
    }

    /**
     * Может ли пользователь запросить перевод чертежа в статус "В работе"
     */
    public static canRequestReturnToWork(design: Design) {
        if (!design.status) return false;
        const statusOk = [DesignStatusEnum.adopted, DesignStatusEnum.inProduction].includes(design.status);
        const rolesOk = DesigningService.isRepresentative(design) || DesigningService.isDesigner(design);
        return statusOk && rolesOk;
    }

    /**
     * Может ли пользователь отклонить запрос перевода чертежа в статус "В работе"
     */
    public static canRejectRequestReturnToWork(design: Design) {
        if (!design.status) return false;
        const statusOk = [DesignStatusEnum.requestReturn].includes(design.status);
        const rolesOk = DesigningService.isInspector(design);
        return statusOk && rolesOk;
    }

    /**
     * Может ли пользователь перевести чертеж в статус "В производстве работ"
     */
    public static canSendToProduction(design: Design) {
        if (!design.status) return false;

        return (
            design.status === DesignStatusEnum.adopted &&
            hasPermission(PermissionsEnum.DesignsToProduction) &&
            (DesigningService.isRepresentative(design) || DesigningService.isInspector(design))
        );
    }

    /**
     * Может ли пользователь перевести чертеж в статус "Аннулирован"
     */
    public static canCancel(design: Design, user?: User) {
        if (!design.status) return false;

        const statusesAreAcceptable =
            DesignStatusEnum.inProduction !== design.status && DesignStatusEnum.cancel !== design.status;
        const userIsAcceptable =
            DesigningService.isInspector(design, user) ||
            DesigningService.isDesigner(design, user) ||
            DesigningService.isRepresentative(design, user);
        return statusesAreAcceptable && userIsAcceptable && hasPermission(PermissionsEnum.DesignsCancel);
    }

    /**
     * Получить текущих Авторов замечаний
     */
    public static getAuthors(design: Design): User[] {
        return get(design, 'authors.data', []);
    }

    /**
     * Получить текущих Кураторов заказчика
     */
    public static getInspectors(design: Design): User[] {
        return get(design, 'inspectors.data', []);
    }

    /**
     * Получить текущих Кураторов проектной организации
     */
    public static getDesigners(design: Design): User[] {
        return get(design, 'designers.data', []);
    }

    /**
     * Получить текущих Исполнителей проектной организации
     */
    public static getExecutors(design: Design): User[] {
        return get(design, 'executors.data', []);
    }

    /**
     * Получить текущих Региональный руководитель со стороны заказчика
     */
    public static getCustomerManagers(design: Design): User[] {
        return get(design, 'customerManagers.data', []);
    }

    /**
     * Получить текущих Главный специалист со стороны Проектной организации
     */
    public static getGeneralDesignersManagers(design: Design): User[] {
        return get(design, 'generalDesignersManagers.data', []);
    }

    /**
     * Обработать прикрепленные файлы задач(замечаний)
     */
    public static async processFilesAttach(
        taskId: number,
        taskFiles: IFile[],
        files: IFile[],
        attach = attachFilesToDesignTask,
        remove = removeFilesFromDesignTask,
    ) {
        const newFilesIds = files
            .filter((file: IFile) => !taskFiles.some(({ id }) => id === file.id))
            .map(({ id }) => id);

        const removedFilesIds =
            taskFiles.filter((file: IFile) => !files.some(({ id }) => id === file.id)).map(({ id }) => id) ?? [];

        if (newFilesIds.length) await attach(taskId, newFilesIds).catch(ErrorService.handleApiError);
        if (removedFilesIds.length) {
            await remove(taskId, removedFilesIds).catch(ErrorService.handleApiError);
        }
    }

    /**
     *
     * 1. Производит необходимые действия для перевода чертежа обратно в статус «В работе»
     * 2. Меняем статус чертежа на статус «В работе»
     */
    public static async returnCompletedDesignToWork({
        id: designId,
        status,
        drawings,
        isModel,
    }: Pick<Design, 'id' | 'status' | 'drawings' | 'isModel'>) {
        const designCase = !isModel && status === DesignStatusEnum.inProduction;
        const modelCase = isModel && status === DesignStatusEnum.adopted;
        if ((designCase || modelCase) && drawings && drawings.data.length) {
            // 1.
            await DesigningService.prepareForReturnToWork({ id: designId, drawings, isModel });
        }

        // 2.
        await returnCompletedDesign(designId);
    }

    /**
     * Производит необходимые действия для перевода чертежа обратно в статус «В работе»
     * 1. Разблокируем в TCloud возможность изменения рабочего документа
     * 2. Деактуализируем последнюю версию чертежа со штампом если чертеж возвращается из статуса «В производстве»
     * 3. Делаем предидущую версию перед штампированной актуальной (TCloud добавляет новую версию)
     * 4. Обновляем версию чертежа в ТПро до последней актуальной
     */
    public static async prepareForReturnToWork({
        id: designId,
        drawings,
        isModel,
    }: Pick<Design, 'id' | 'drawings' | 'isModel'>) {
        if (drawings && drawings.data.length) {
            const lastDrawing = last(drawings.data);
            const { documentId, versionIndex } = TCloudService.parseFileUid(lastDrawing!.uid);

            // 1.
            await TCloudService.disagreeDocument(documentId);

            if (!isModel) {
                // 2.
                await TCloudService.deactualizeDocumentVersion(documentId, versionIndex);

                // 3.
                const {
                    data: { data: actualizedDocument },
                } = await TCloudService.headDocumentVersion(documentId, versionIndex - 1);

                // 4.
                await DesigningService.updateDrawingVersionForced(designId, {
                    name: actualizedDocument.name ?? '',
                    fileUrl: TCloudService.makeUrlLinkDownloadable(actualizedDocument.downloadUri),
                    uid: TCloudService.createFileUid({
                        id: actualizedDocument.id,
                        version: actualizedDocument.version,
                    }),
                });
            }
        }
    }

    public static async returnDesignToWorkFromRequest(design: Design) {
        const response = await DesigningService.acceptRequestReturnToWork(design.id);
        const requests = response.toWorkRequests?.data;
        const lastRequest = last(requests);

        const designCase = lastRequest && !design.isModel && lastRequest.designStatus === DesignStatusEnum.inProduction;
        const modelCase = lastRequest && design.isModel && lastRequest.designStatus === DesignStatusEnum.adopted;
        if (designCase || modelCase) {
            await DesigningService.prepareForReturnToWork(design);
        }
    }

    /**
     * Производит необходимые действия для перевода чертежа в статус «В производстве» из статуса «Принят»
     * 1. Ставим штамп на версию в TCloud на последний чертеж (TCloud добавляет новую версию)
     * 2. Блокируем в TCloud возможность изменения рабочего документа
     * 3. Актуализируем новую версию со штампом для ТПро
     * 4. Меняем статус чертежа на статус «В производстве»
     */
    public static async sendDesignToProduction({
        id: designId,
        status,
        drawings,
    }: Pick<Design, 'id' | 'status' | 'drawings'>) {
        if (status !== DesignStatusEnum.adopted || !drawings || !drawings.data.length) return;

        // 1.
        const lastDrawing = last(drawings.data);
        const { documentId, versionIndex } = TCloudService.parseFileUid(lastDrawing!.uid);
        const {
            data: { data: newVersion },
        } = await TCloudService.putStampOnDocumentVersion(documentId, versionIndex);

        // 2.
        await TCloudService.agreeDocument(documentId);

        // 3.
        await DesigningService.updateDrawingVersionForced(designId, {
            name: newVersion.document.name,
            fileUrl: TCloudService.makeUrlLinkDownloadable(newVersion.downloadUri),
            uid: TCloudService.createFileUid({ id: newVersion.documentId, version: newVersion }),
        });

        // 4.
        await toProductionDesign(designId);
    }

    /**
     * Производит необходимые действия для простановки галочки для модели в TCloud в статусе «Принят»
     * 1. Блокируем в TCloud возможность изменения рабочего документа
     */
    public static async markModelAgreed({ status, drawings }: Pick<Design, 'status' | 'drawings'>) {
        if (status !== DesignStatusEnum.adopted || !drawings || !drawings.data.length) return;

        const lastDrawing = last(drawings.data);
        const { documentId } = TCloudService.parseFileUid(lastDrawing!.uid);

        await TCloudService.agreeDocument(documentId);
    }

    // API

    public static completeDesign(id: number, data: { uid: string; fileUrl: string; name: string }) {
        return Http.post<ITransformedValue<Design>>(`/designs/${id}/complete`, data);
    }

    public static updateDrawingVersion(id: number, data: { uid: string; fileUrl: string; name: string }) {
        return Http.post<ITransformedValue<Design>>(`/designs/${id}/new/drawing`, data);
    }

    public static updateDrawingVersionForced(designId: number, data: { uid: string; fileUrl: string; name: string }) {
        return Http.post<ITransformedValue<Design>>(`/designs/${designId}/new/drawing/force`, data);
    }

    public static cancelDesign(designId: number, data: { description: string }) {
        return Http.post<ITransformedValue<Design>>(`/designs/${designId}/cancel`, data);
    }

    public static requestReturnToWork(designId: number, data: { description: string }) {
        return Http.post<ITransformedValue<Design>>(`/designs/${designId}/request-to-work`, data);
    }

    public static rejectRequestReturnToWork(designId: number) {
        return Http.get<ITransformedValue<Design>>(`/designs/${designId}/request-to-work/reject`);
    }

    public static async acceptRequestReturnToWork(designId: number) {
        return (await Http.get<ITransformedValue<Design>>(`/designs/${designId}/request-to-work/accept`)).data.data;
    }

    /**
     * Получить список ответственных за задачу(для создания/изменения задачи)
     */
    public static async getTaskExecutors(projectId: number) {
        return (await Http.get<ITransformedValue<User[]>>(`/designs-task-executors/project/${projectId}`)).data.data;
    }

    public static async getAllTaskExecutors(projectId: number) {
        return (
            await Http.get<{
                customerExecutors: IIncludedValue<User[]>;
                customerRepresentative: IIncludedValue<User[]>;
                customerManagers: IIncludedValue<User[]>;
                generalDesignersExecutors: IIncludedValue<User[]>;
                generalDesignersRepresentative: IIncludedValue<User[]>;
                generalDesignersManagers: IIncludedValue<User[]>;
            }>(`/designs-all-executors/project/${projectId}`)
        ).data;
    }

    public static async toWorkDesignTask(taskId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(`/designs/author-task/${taskId}/to-work`, {
            params: { include: 'files,author' },
        }).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async completeDesignTask(taskId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(`/designs/author-task/${taskId}/complete`, {
            params: { include: 'files,author' },
        }).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async acceptDesignTask(taskId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(`/designs/author-task/${taskId}/accept`, {
            params: { include: 'files,authors.affiledBy,executors.affiledBy' },
        }).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async toWorkDesignRemark(taskId: number) {
        return (await Http.get<ITransformedValue<DesignRemark>>(`/designs/task/${taskId}/to-work`)).data.data;
    }

    public static async completeDesignRemark(taskId: number) {
        return (await Http.get<ITransformedValue<DesignRemark>>(`/designs/task/${taskId}/complete`)).data.data;
    }

    public static async rejectDesignRemark(taskId: number, data: { description: string }) {
        const response = await Http.post<ITransformedValue<DesignRemark>>(`/designs/task/${taskId}/reject`, data).catch(
            ErrorService.handleApiError,
        );
        if (response) return response.data.data;
    }

    public static async changeRejectionDesignRemark(taskId: number, data: { description: string }) {
        const response = await Http.post<ITransformedValue<DesignRemark>>(
            `/designs/task/${taskId}/reject/change`,
            data,
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async toWorkDesignTaskExecutor(taskId: number, executorId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${taskId}/to-work/${executorId}`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async acceptDesignTaskExecutor(taskId: number, executorId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${taskId}/accept/${executorId}`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async agreeDesignTaskExecutor(taskId: number, executorId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${taskId}/agreed/by-executor/${executorId}`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async formCommentsDesignTaskExecutor(taskId: number, executorId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${taskId}/comments-formed/by-executor/${executorId}`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async changeDeadlineDesignTask(taskId: number, data: { description: string; deadline: string }) {
        const response = await Http.post<ITransformedValue<DesignTask>>(
            `/designs/author-task/${taskId}/change-deadline`,
            data,
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async acceptDeadlineDesignTask(deadlineId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${deadlineId}/deadline/accept`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }

    public static async rejectDeadlineDesignTask(deadlineId: number) {
        const response = await Http.get<ITransformedValue<DesignTask>>(
            `/designs/author-task/${deadlineId}/deadline/reject`,
            { params: { include: 'authors.affiledBy,executors.affiledBy,changeDeadline' } },
        ).catch(ErrorService.handleApiError);
        if (response) return response.data.data;
    }
}
